import { getApmWeb } from "@lib/observability";
import { isPlatformBrowser } from "@utils/common";
import { getConfigEndpointByEnv } from "@utils/config";
import { type Client as WSClient, createClient } from "graphql-ws";
import { useSession } from "next-auth/react";
import { createContext, type FC, useContext, useEffect, useState } from "react";
import type { SafeConfigType } from "src/config";
import { useConfig } from "src/config/hook";

const getWSEndpointByEnv = (config: SafeConfigType): string => {
  const { wsEndpoint, wsEndpointStaging } = config;

  const endpoint = getConfigEndpointByEnv(wsEndpoint, wsEndpointStaging, config);

  if (endpoint) {
    return endpoint;
  }

  getApmWeb().captureError(
    new Error("production graphql endpoint not set"),
    "web-socket-client-provider",
  );
  throw new Error("production wideview graphql endpoint not set");
};

const WSContext = createContext<WSClient | undefined>(undefined);

export const WebSocketClientProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [wsClient, setWsClient] = useState<WSClient | undefined>();
  const session = useSession();
  const config = useConfig();

  useEffect(() => {
    if (!isPlatformBrowser() || !session.data?.idToken) {
      setWsClient(undefined);
      return;
    }

    const wsConfig = {
      url: getWSEndpointByEnv(config),
      keepAlive: 60_000, // ping server every 60 seconds,
      connectionParams: () => {
        return {
          authorization: `Bearer ${session.data?.idToken}`,
        };
      },
    };

    const client = createClient(wsConfig);
    setWsClient(client);

    return () => {
      client?.dispose();
    };
  }, [session.data?.idToken, config]);

  return <WSContext.Provider value={wsClient}>{children}</WSContext.Provider>;
};
export const useWS = () => {
  const context = useContext(WSContext);
  return context;
};
