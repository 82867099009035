import { createContext, useContext } from "react";

import type { SafeConfigType } from "./index";

export const ConfigContext = createContext<SafeConfigType | undefined>(undefined);

export const useConfig = (): SafeConfigType => {
  const config = useContext(ConfigContext);

  if (!config) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return config;
};
