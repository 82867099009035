import { Typography, type TypographyProps } from "@infinitaslearning/pixel-design-system";
import type { TOptions } from "i18next";
import { type TFunction, i18n } from "next-i18next";
import { type ForwardedRef, type ReactNode, forwardRef } from "react";

// Now an interface is allowed to extend another interface
interface PepTypographyProps extends TypographyProps {
  t?: TFunction;
  translationKey?: string;
  tOptions?: TOptions;
  children?: ReactNode;
}

/**
 * Similar as Typography from Pixel Design System, but adds a testId to identify whether the fallback language is being used
 * If the PepTypography needs to use more than one translation at once, a variable or component you need to pass those as children.
 * If not you pass the translations using the variables below:
 * So: <PepTypography t={t} translationKey="key" tOptions={{ variable: "value" }} /> -> Can be checked in E2E tests
 * OR: <PepTypography> t({key}, {variable: "value"}) <Component /> {SOME_VAR} </PepTypography> -> Can't be checked in E2E tests
 * Ideally we want to display a singular translation in a PepTypography component,
 * but if you need to display more than one translation at once, you must use the second approach.
 * @param t - Translation function
 * @param translationKey - Translation key to be used
 * @param tOptions - Translation options, used for variables in the translation
 * @param children - Extra children in case more components than just the text needs to be added
 * @returns Typography component
 */
export const PepTypography = forwardRef(
  (
    { t, translationKey, tOptions, children, ...rest }: PepTypographyProps,
    ref: ForwardedRef<HTMLSpanElement>,
  ) => {
    if (t && translationKey) {
      // The last item in the array is the fallback language
      const fallbackLanguage = i18n?.languages.slice(-1)[0];
      const currentLanguage = i18n?.language;
      const translation = tOptions ? t(translationKey, tOptions) : t(translationKey);
      const isUsingFallback = t(translationKey, { lng: fallbackLanguage }) === translation;

      const getLangDataAttr = (isUsingFallback: boolean) => {
        const baseAttr = "lang";
        return isUsingFallback
          ? `${baseAttr}-${fallbackLanguage}`
          : `${baseAttr}-${currentLanguage}`;
      };

      const dataLang = getLangDataAttr(isUsingFallback);
      return (
        <Typography ref={ref} data-lang={dataLang} {...rest}>
          {tOptions ? t(translationKey, tOptions) : t(translationKey)}
          {children}
        </Typography>
      );
    }
    return (
      <Typography ref={ref} {...rest}>
        {children}
      </Typography>
    );
  },
);
