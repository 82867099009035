import { BrandContext } from "@components/brand-context/brand-context";
import { BRAND_COMPONENTS } from "@utils/brand-components";
import { isLocalEnv } from "@utils/config";
import { getOpCoWithLocale, type OpCo, type OpCoInfo } from "@utils/get-opco-with-locale";
import { useRouter } from "next/router";
import { useContext } from "react";
import { useConfig } from "src/config/hook";
import { isEmpty } from "lodash";

// Helper that returns the correct OpCo and Locale based on the hostname
// accepts an argument to handle the server side rendering case
export const useBrand = (hostname?: string): OpCoInfo => {
  const brandContext = useContext(BrandContext);
  const router = useRouter();
  const config = useConfig();

  // If we have brand's information in the context then return it
  if (brandContext) return brandContext;

  const opCoWithLocale = getOpCoWithLocale({ config, hostname, locale: router.locale });

  if (isLocalEnv(config) && !isEmpty(config.overrideOpcoTheme))
    return {
      ...opCoWithLocale,
      components: BRAND_COMPONENTS[config.overrideOpcoTheme as OpCo],
    };

  return {
    ...opCoWithLocale,
    components: BRAND_COMPONENTS[opCoWithLocale.opCo],
  };
};
