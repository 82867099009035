import { BrandContext } from "@components/brand-context/brand-context";
import { Loading } from "@components/loading";
import { CacheProvider } from "@emotion/react";
import {
  CssBaseline,
  GlobalStyles,
  type Theme,
  ThemeProvider,
} from "@infinitaslearning/pixel-design-system";
import { getAnalyticsTracker, initializeAnalyticsTracker } from "@lib/analytics-tracker";
import { queryClient } from "@lib/react-query";
import { setLocalSession } from "@lib/session-store";
import { LoggedOutView } from "@components/logged-out-view";
import type { AppPropsWithLayout } from "@pages/types";
import createEmotionCache from "@styles/createEmotionCache";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { isPlatformBrowser } from "@utils/common";
import { getOpCoWithLocale } from "@utils/get-opco-with-locale";
import {
  getSafeTargetOrigins,
  INIT_MESSAGE_TYPE,
  REFRESH_TOKEN_MESSAGE_TYPE,
} from "@utils/scoodleplay/iframe-communication";
import { useBrand } from "@utils/use-brand";
import useServiceWorker from "@utils/use-service-worker";
import { SessionProvider, useSession } from "next-auth/react";
import { appWithTranslation, useTranslation } from "next-i18next";
import type { AppContext } from "next/app";
import App from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { useContext, useEffect } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import GlobalStyle from "src/styles/global-styling/global.styles";
import { getPixelBrandTheme } from "src/styles/theme";
import { initObservabilityWeb } from "@lib/observability";
import useGlobalErrorHandler from "@utils/use-global-error-handler";
import { getGlobalErrorTitle } from "@utils/get-global-error-title";
import { PlatformError } from "@components/platform-error/platform-error";
import { isIframe } from "@utils/is-iframe";
import dynamic from "next/dynamic";
import { ErrorBoundary, type FallbackProps } from "react-error-boundary";
import { useState } from "react";
import { GlobalErrorAlert } from "@components/global-error-handler/global-error-alert";
import {
  GlobalErrorBoundaryContext,
  GlobalErrorBoundaryProvider,
} from "@components/global-error-handler/global-error-context";

const MAX_RETRIES = 3;
const HEARTBEAT_INTERVAL = 1000;

const DynamicLoggedInView = dynamic(
  () => import("@components/logged-in-view").then((mod) => ({ default: mod.LoggedInView })),
  { ssr: false },
);
import { HomePlusReloadCTA } from "@components/platform-error/platform-error-ctas";

import { ConfigContext } from "src/config/hook";
import React from "react";
import type { SafeConfigType } from "src/config";
import { isProductionEnv, isTestEnv } from "@utils/config";

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      body: {
        height: "100%",
      },
      html: {
        height: "100%",
      },
    }}
  />
);

const resetTheme = (theme: Theme): Theme => ({
  ...theme,
  components: {
    ...theme.components,
    MuiCssBaseline: {
      ...theme.components?.MuiCssBaseline,
    },
  },
});

const clientSideEmotionCache = createEmotionCache();

let hostname = undefined;
if (isPlatformBrowser()) {
  hostname = window?.location?.hostname;
}

type PagesProps = {
  Component: AppPropsWithLayout["Component"];
  pageProps: AppPropsWithLayout["pageProps"];
};

const ID_TOKEN_COOKIE_NAME = "id_token";
const SIGNED_IN_COOKIE_NAME = "signed_in";
const HEARTBEAT_MESSAGE_TYPE = "pep-heartbeat";

const Pages = ({ Component, pageProps }: PagesProps) => {
  const router = useRouter();
  const [_, setCookie, removeCookie] = useCookies([ID_TOKEN_COOKIE_NAME, SIGNED_IN_COOKIE_NAME]);

  const { data, status } = useSession({
    required: true,
    onUnauthenticated: () => {
      // This is kinda of funny and very sad at the same time.
      // If we remove this `onUnauthenticated` callback, the `useSession` of next-auth will
      // make our app loop in a infinite loop of redirects (not always, for example in e2e tests).
      // So, at least until we don't find a better solution, we need to keep this callback here.
      // I hope this comment will help the next developer to understand why this is here because I lost more than 1 day
      // and almost cry because of this
    },
  });

  const { globalError, hasGlobalError } = useGlobalErrorHandler();

  setLocalSession(data, status);

  useEffect(() => {
    if (data?.idToken) {
      const cookieOptions = {
        path: "/",
        expires: new Date(data?.expires as number),
        secure: true,
        sameSite: "strict",
        domain: `.${location.hostname.split(".").reverse().splice(0, 2).reverse().join(".")}`,
      } as const;
      // we need to make the idToken (jwt) available to embedded player services like linguineo/slimstampen
      setCookie(ID_TOKEN_COOKIE_NAME, data?.idToken, cookieOptions);
      setCookie(SIGNED_IN_COOKIE_NAME, true, cookieOptions);
    }
    return () => {
      removeCookie(ID_TOKEN_COOKIE_NAME);
      removeCookie(SIGNED_IN_COOKIE_NAME);
    };
  }, [data, removeCookie, setCookie]);

  useEffect(() => {
    let heartBeat: NodeJS.Timeout | undefined;
    // this is needed to send the event to the parent window (in case of PEP embedded in scoodle play)
    // in order to let them close the iframe and display the result screen in scoodle play
    if (isIframe() && data?.idToken && router.asPath !== "/scoodleplay-logout") {
      const token_pep = `Bearer ${data.idToken}`;

      try {
        // biome-ignore lint/complexity/noForEach: forEach is used to send the message to all the origins
        getSafeTargetOrigins(pageProps.config).forEach((safeTargetOrigin) => {
          window.top?.postMessage(
            JSON.stringify({ eventName: INIT_MESSAGE_TYPE, token_pep }),
            safeTargetOrigin,
          );
          window.top?.postMessage(
            JSON.stringify({ eventName: REFRESH_TOKEN_MESSAGE_TYPE, token_pep }),
            safeTargetOrigin,
          );
        });

        heartBeat = setInterval(() => {
          window.top?.postMessage(JSON.stringify({ eventName: HEARTBEAT_MESSAGE_TYPE }), "*");
        }, HEARTBEAT_INTERVAL);
      } catch {
        // swallow error, as only one origin will eventually be valid
      }
    }

    return () => {
      if (heartBeat) {
        clearInterval(heartBeat);
      }
    };
  }, [data?.idToken, router.asPath, pageProps.config]);

  // status will never be "unauthorized" because we use `required: true` on useSession
  if (status === "loading") {
    if (
      router.pathname.startsWith("/unauthenticated") ||
      router.pathname.startsWith("/unauthorized")
    ) {
      return <LoggedOutView Component={Component} pageProps={pageProps} />;
    }

    return <Loading />;
  }

  return (
    <>
      {hasGlobalError && <GlobalErrorAlert error={globalError} />}
      <QueryClientProvider client={queryClient}>
        <DynamicLoggedInView Component={Component} pageProps={pageProps} />
        {process.env.NEXT_PUBLIC_REACT_QUERY_DEVTOOLS_POSITION && (
          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition={
              process.env.NEXT_PUBLIC_REACT_QUERY_DEVTOOLS_POSITION as
                | "bottom-left"
                | "bottom-right"
            }
          />
        )}
      </QueryClientProvider>
    </>
  );
};

const GlobalErrorFallback = ({
  error,
  resetErrorBoundary,
  retryCount,
  pageProps,
}: FallbackProps & { retryCount: number; pageProps: PagesProps["pageProps"] }) => {
  const brand = useBrand(pageProps.hostname);
  const { isValidError } = useGlobalErrorHandler();
  const theme = getPixelBrandTheme(brand.opCo);
  const custTheme = resetTheme(theme);

  const { locale } = getOpCoWithLocale({ config: pageProps.config });
  const scrollbarColor = custTheme.pixel.color.onSurface.variant;

  // isValidError is used to check if the error is a known error that we can recover from.
  if (isValidError(error) && retryCount < MAX_RETRIES) {
    resetErrorBoundary();
  }

  return (
    <>
      <Head>
        <title>{getGlobalErrorTitle(locale)}</title>
      </Head>
      <ThemeProvider theme={custTheme}>
        <CssBaseline />
        <GlobalStyle scrollbarColor={scrollbarColor} />
        {inputGlobalStyles}
        <BrandContext.Provider value={brand}>
          <PlatformError error={error} CustomCTA={<HomePlusReloadCTA />} />
        </BrandContext.Provider>
      </ThemeProvider>
    </>
  );
};

const PEPPupil = ({
  Component,
  pageProps,
  // @ts-ignore: this is failing due to emotion using different versions of emotion cache for some reason. Though the difference is small enough to call ignore here.
  // TODO check if this ignore can be removed when emotion is updated again
  emotionCache = clientSideEmotionCache,
}: AppPropsWithLayout) => {
  const brand = useBrand(pageProps.hostname);
  const theme = getPixelBrandTheme(brand.opCo);
  const custTheme = resetTheme(theme);
  const { t: commonTranslate } = useTranslation("common", { keyPrefix: "platform" });
  const sessionRefetchInterval = 6 * 60 * 60; // 6 hours
  const scrollbarColor = custTheme.pixel.color.onSurface.variant;

  useEffect(() => {
    const tracker = getAnalyticsTracker();
    tracker?.awaitInitialized?.().then(() => tracker.listen());
  }, []);

  useServiceWorker();
  const [retryCount, setRetryCount] = useState(0);
  const { setErrorCaught } = useContext(GlobalErrorBoundaryContext);

  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="description" content="PEP" />
          <link key="favicon" rel="icon" href={`/favicon-${brand.opCo}.ico`} />
          <title>{commonTranslate("name")}</title>
        </Head>
        <CookiesProvider>
          <SessionProvider
            refetchInterval={sessionRefetchInterval}
            session={pageProps.session}
            refetchOnWindowFocus={false}
          >
            <GlobalErrorBoundaryProvider>
              <ThemeProvider theme={custTheme}>
                <CssBaseline />
                {inputGlobalStyles}
                <GlobalStyle scrollbarColor={scrollbarColor} />
                <BrandContext.Provider value={brand}>
                  <ErrorBoundary
                    onError={(error) => {
                      setErrorCaught(error);
                    }}
                    onReset={() => {
                      setRetryCount((prev) => prev + 1);
                    }}
                    fallbackRender={({ error, resetErrorBoundary }) => (
                      <GlobalErrorFallback
                        error={error}
                        resetErrorBoundary={resetErrorBoundary}
                        retryCount={retryCount}
                        pageProps={pageProps}
                      />
                    )}
                  >
                    <Pages Component={Component} pageProps={pageProps} />
                  </ErrorBoundary>
                </BrandContext.Provider>
              </ThemeProvider>
            </GlobalErrorBoundaryProvider>
          </SessionProvider>
        </CookiesProvider>
      </CacheProvider>
    </>
  );
};

const PEPPupilWrapper = ({
  Component,
  pageProps,
  router,
  // @ts-ignore: this is failing due to emotion using different versions of emotion cache for some reason. Though the difference is small enough to call ignore here.
  // TODO check if this ignore can be removed when emotion is updated again
  emotionCache = clientSideEmotionCache,
}: AppPropsWithLayout) => {
  const configRef = React.useRef<SafeConfigType | undefined>(pageProps.config);
  if (pageProps.config && pageProps.config !== configRef.current) {
    configRef.current = pageProps.config;
  }

  if (!configRef.current) {
    return null;
  }

  // we want to setup the observability module as soon as possible
  initObservabilityWeb(configRef.current);

  if (isPlatformBrowser() && !isTestEnv(configRef.current)) {
    const { opCo, locale } = getOpCoWithLocale({ config: configRef.current });
    // Set HTML lang from here so that the lang attribure set in _document.page.tsx gets overwritten
    // That way the automatic translate suggestion will no longer show up
    document.documentElement.lang = locale;

    initializeAnalyticsTracker(
      {
        environment:
          isProductionEnv(configRef.current) && hostname !== "localhost" ? "production" : "local",
        opCo,
        platform: "PEP",
        platformVariant: "teacher",
      },
      configRef.current,
    );
  }

  return (
    <>
      <ConfigContext.Provider value={configRef.current}>
        <PEPPupil
          pageProps={pageProps}
          Component={Component}
          router={router}
          emotionCache={emotionCache}
        />
      </ConfigContext.Provider>
    </>
  );
};

PEPPupilWrapper.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  appProps.pageProps.hostname = appContext?.ctx?.req?.headers?.host?.split(":")[0] || "";

  if (typeof window === "undefined") {
    const { getClientConfig } = await import("../config");

    // Here we are on the server, but we'll pass the config to the client, so we need to filter out the secrets
    // to do that we use the getClientConfig function, not the getConfig function
    const ilptClientConfig = await getClientConfig();
    return {
      pageProps: {
        config: ilptClientConfig,
        ...appProps.pageProps,
      },
    };
  }
  return { ...appProps };
};

export default appWithTranslation(PEPPupilWrapper);
