import { isLocalEnv } from "@utils/config";
import { isIframe } from "@utils/is-iframe";
import type { SafeConfigType } from "src/config";

export const getSafeTargetOrigins = (config: SafeConfigType) =>
  isLocalEnv(config)
    ? ["*"]
    : ["https://scoodleplay.acc.plantyn.com", "https://scoodleplay.plantyn.com/"];

const CLOSE_PEP_IFRAME_MESSAGE_TYPE = "close_pep_iframe";
const EXERCISE_COMPLETE_EVENT_NAME = "pepExerciseComplete";
export const INIT_MESSAGE_TYPE = "initialize_pep";
export const REFRESH_TOKEN_MESSAGE_TYPE = "pep_refresh_token";

export const closePepIframe = () => {
  window.top?.postMessage(JSON.stringify({ eventName: CLOSE_PEP_IFRAME_MESSAGE_TYPE }), "*");
};

export const sendIFrameCompleteEvent = (
  isLearningUnitFinished: boolean,
  numberOfStarsToDisplay: number,
  selectedLearningUnit?: { id: string } | null,
) => {
  // this is needed to send the event to the parent window (in case of PEP embedded in scoodle play)
  // in order to let them close the iframe and display the result screen in scoodle play
  if (isLearningUnitFinished && !!selectedLearningUnit && isIframe()) {
    window.top?.postMessage(
      JSON.stringify({
        eventName: EXERCISE_COMPLETE_EVENT_NAME,
        stars: numberOfStarsToDisplay,
        // there's a typo here 👇, but we need to support this until we switch to the new one in scoodle play
        selectedLearningUnitI: selectedLearningUnit.id,
        selectedLearningUnitId: selectedLearningUnit.id,
      }),
      "*",
    );
  }
};
