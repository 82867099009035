import { createContext, type ReactNode, useState } from "react";

type GlobalErrorBoundaryContextType = {
  errorCaught: Error | null;
  setErrorCaught: React.Dispatch<React.SetStateAction<Error | null>>;
};

export const GlobalErrorBoundaryContext = createContext<GlobalErrorBoundaryContextType>({
  errorCaught: null,
  setErrorCaught: () => {},
});

export const GlobalErrorBoundaryProvider = ({ children }: { children: ReactNode }) => {
  const [errorCaught, setErrorCaught] = useState<Error | null>(null);

  return (
    <GlobalErrorBoundaryContext.Provider value={{ errorCaught, setErrorCaught }}>
      {children}
    </GlobalErrorBoundaryContext.Provider>
  );
};
